import React from 'react';
import { graphql, navigate } from 'gatsby';
import { Typography, Button } from 'antd';
import styled from 'styled-components';

import FullWidthBackground from '../components/FullWidthBackground';
import {default as BaseCenterContent} from '../components/CenterContent';
import siteContentWidth from '../styles/styled-components/reuseable-css/site-content-width';

const { Title: BaseTitle, Paragraph: BaseParagraph } = Typography;

const CenterContent = styled(BaseCenterContent)`
	${siteContentWidth};
`;

const Title = styled(BaseTitle)`
	color: ${props => props.theme.colors.white}!important;
	font-size: 10rem!important;
	margin-bottom: 1rem!important;
`;

const Paragraph = styled(BaseParagraph)`
	color: ${props => props.theme.colors.white};
	text-align: center;
`;

const NotFoundPage = ({ data }) => {
  return (
    <FullWidthBackground
      data={data.backgroundImage}
      title="hero image"
      noHeight
    >
      <CenterContent>
        <Title level={1}>404</Title>
        <Paragraph>
					There's nothing here{` `}
          <span role="img" aria-label="smily face">
            &#129488;
          </span>
        </Paragraph>
        <Paragraph strong>
          Please go to our homepage and navigate using the menu{' '}
          <span role="img" aria-label="smily face">
            &#128513;
          </span>
        </Paragraph>
        <Button ghost size="large" onClick={e => navigate('/')}>
          Home
        </Button>
      </CenterContent>
    </FullWidthBackground>
  );
};

export const query = graphql`
  query fourZeroFourPageQuery {
    backgroundImage: file(relativePath: { eq: "page/404/404-background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500, maxHeight: 1500, jpegQuality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default NotFoundPage;
